import logo from './logo.svg';
import './App.css';
import Main from './main.js'


function App() {

  
  return (
    <div className="App">
      
        <Main />
      </div>
    
  );
}

export default App;
